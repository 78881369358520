import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { ChatTemplate } from "app-types/template";
import PlainHeader from "components/ui/base/PlainHeader";
import { CHAT_TEMPLATES_PATH } from "constants/paths";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import ChatTemplates from "components/ui/extended/ChatTemplates";
import BlogPostsBox from "components/ui/base/BlogPostsBox";
import { TRIGGER_TEMPLATES } from "constants/templates";

interface LiveChatTemplatesPageProps {
  location: Location;
}

export default ({ location }: LiveChatTemplatesPageProps) => {
  const { t } = useTranslation();

  const totalTemplates = TRIGGER_TEMPLATES.length;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.live-chat-templates.seo.title")}
        description={t("pages.live-chat-templates.seo.description")}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: t("pages.live-chat-templates.seo.title"),
        }}
      />
      <PlainHeader
        title={t("pages.live-chat-templates.title")}
        description={t("pages.live-chat-templates.description", {
          count: totalTemplates,
        })}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Templates",
            path: "/templates/",
          },
          {
            name: "Live Chat Templates",
            path: `${CHAT_TEMPLATES_PATH}/`,
          },
        ]}
      />

      <ChatTemplates templates={TRIGGER_TEMPLATES} />

      <QuestionsAndAnswers
        title={t("pages.live-chat-templates.faq.title")}
        description={t("pages.live-chat-templates.faq.description")}
        elements={[
          {
            question: t("pages.live-chat-templates.faq.1.question"),
            answer: t("pages.live-chat-templates.faq.1.answer"),
          },
          {
            question: t("pages.live-chat-templates.faq.2.question"),
            answer: t("pages.live-chat-templates.faq.2.answer"),
          },
        ]}
      />

      <BlogPostsBox
        category={"live-chat"}
        title={"Live Chat Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />

      <RegisterForCustomerService />
      <Customers />
    </GlobalWrapper>
  );
};
